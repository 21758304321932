import {SetValueType, SortOrder} from 'types';
import {PageIds, QuickLinkDef, TabElements} from 'modules/decisions/types';
import {IndustryLevel} from 'modules/decisionsNext/api/types';
import {ProductInsuranceSegment} from 'modules/decisionsNext/types';

import {
  DECISIONS_PAGES_NEW_BUSINESS_PRODUCERS,
  DECISIONS_PAGES_OPPORTUNITIES,
  DECISIONS_PAGES_RENEWALS,
  OPPORTUNITIES,
  RENEWALS,
} from 'core/constants/paths';

/**
 * Containers tabs mapping
 */
export const tabsMap = {
  [TabElements.All]: {
    id: TabElements.All,
    title: 'All',
  },
  [TabElements.CommercialLines]: {
    id: TabElements.CommercialLines,
    title: 'Commercial',
  },
  [TabElements.EmployeeBenefits]: {
    id: TabElements.EmployeeBenefits,
    title: 'Benefits',
  },
  [TabElements.Overview]: {
    id: TabElements.Overview,
    title: 'Overview',
  },
  [TabElements.Market]: {
    id: TabElements.Market,
    title: 'Market',
  },
  [TabElements.Industry]: {
    id: TabElements.Industry,
    title: 'Industry',
  },
  [TabElements.Intermediary]: {
    id: TabElements.Intermediary,
    title: 'Intermediary',
  },
  [TabElements.Form5500]: {
    id: TabElements.Form5500,
    title: 'Form 5500',
  },
  [TabElements.Lost]: {
    id: TabElements.Lost,
    title: 'Lost',
  },
  [TabElements.LostBusiness]: {
    id: TabElements.LostBusiness,
    title: 'Lost Business',
  },
  [TabElements.PersonalLines]: {
    id: TabElements.PersonalLines,
    title: 'Personal',
  },
  [TabElements.Placement]: {
    id: TabElements.Placement,
    title: 'Placement',
  },
  [TabElements.Producer]: {
    id: TabElements.Producer,
    title: 'Producer',
  },
  [TabElements.Product]: {
    id: TabElements.Product,
    title: 'Product',
  },
  [TabElements.Ratings]: {
    id: TabElements.Ratings,
    title: 'Ratings',
  },
  [TabElements.Renewed]: {
    id: TabElements.Renewed,
    title: 'Renewed',
  },
  [TabElements.Type]: {
    id: TabElements.Type,
    title: 'Type',
  },
  [TabElements.Client]: {
    id: TabElements.Client,
    title: 'Client',
  },
  [TabElements.BrokerAgency]: {
    id: TabElements.BrokerAgency,
    title: 'Broker / Agency',
  },
  [TabElements.Clients]: {
    id: TabElements.Clients,
    title: 'Clients',
  },
  [TabElements.Policies]: {
    id: TabElements.Policies,
    title: 'Policies',
  },
  [TabElements.ProductionCredit]: {
    id: TabElements.ProductionCredit,
    title: 'Production Credit',
  },
  [TabElements.Monoline]: {
    id: TabElements.Monoline,
    title: 'Monoline',
  },
  [TabElements.TargetIndustry]: {
    id: TabElements.TargetIndustry,
    title: 'Target Industry',
  },
  [TabElements.Prospects]: {
    id: TabElements.Prospects,
    title: 'Prospects',
  },
  [TabElements.WinBack]: {
    id: TabElements.WinBack,
    title: 'Win Back',
  },
  [TabElements.CrossSell]: {
    id: TabElements.CrossSell,
    title: 'Cross Sell',
  },
};

// we have to use something different from slash, because our entity ids could have a slash
export const SUB_TAB_DELIMITER = '::';

export const DEFAULT_DECISION_ENTITY_SORT = {
  by: 'REVENUE',
  order: SortOrder.Desc,
};

export const industryLevelToNextLevelMap = {
  [IndustryLevel.Group]: IndustryLevel.SubGroup,
  [IndustryLevel.SubGroup]: IndustryLevel.Industry,
};

export const levelToIdApiKeyMap: Record<
  IndustryLevel.SubGroup | IndustryLevel.Industry,
  'industrySubGroupIds' | 'industryGroupIds'
> = {
  [IndustryLevel.SubGroup]: 'industryGroupIds',
  [IndustryLevel.Industry]: 'industrySubGroupIds',
};

const QUICK_LINKS_RAW = {
  WinRatio: {title: 'Win Ratio'},
  Opportunities: {title: 'Opportunities', to: DECISIONS_PAGES_OPPORTUNITIES},
  Retention: {id: PageIds.Retention, title: 'Retention'},
  Activity: {title: 'Activity'},
  Validation: {title: 'Validation'},
  DataAudits: {title: 'Data Audits'},
  Clients: {title: 'Clients'},
  Monoline: {title: 'Monoline'},
  Documents: {title: 'Documents'},
  Renewals: {
    title: 'Renewals',
    to: DECISIONS_PAGES_RENEWALS,
  },
  Contacts: {title: 'Contacts'},
  ProducerGoals: {
    id: PageIds.ProducerGoals,
    title: 'Producer Goals',
    to: DECISIONS_PAGES_NEW_BUSINESS_PRODUCERS,
  },
  Commissions: {title: 'Commissions'},
  CarrierAppetite: {title: 'Carrier Appetite'},
} as const;

export const QUICK_LINKS: SetValueType<typeof QUICK_LINKS_RAW, QuickLinkDef> = QUICK_LINKS_RAW;

const QUICK_ENTITY_LINKS_RAW = {
  Opportunities: {...QUICK_LINKS_RAW.Opportunities, to: OPPORTUNITIES},
  Renewals: {...QUICK_LINKS_RAW.Renewals, to: RENEWALS},
} as const;

export const QUICK_ENTITY_LINKS: SetValueType<typeof QUICK_ENTITY_LINKS_RAW, QuickLinkDef> = QUICK_ENTITY_LINKS_RAW;

export type SegmentsOption = {value: ProductInsuranceSegment; label: string};

export const segmentsMap: Record<ProductInsuranceSegment, SegmentsOption> = {
  all: {value: 'all', label: 'All'},
  commercial: {value: 'commercial', label: 'Commercial'},
  benefits: {value: 'benefits', label: 'Benefits'},
  personal: {value: 'personal', label: 'Personal'},
};

export const nullableKeysForApolloResponse = ['timestamp'];
