import {Theme} from '@mui/material/styles';

import {chip} from 'core/themes/themeDecisions/overrides/chip';
import {autocomplete} from 'core/themes/themeDecisions/overrides/autocomplete';
import {listItem} from 'core/themes/themeDecisions/overrides/listItem';

import {alert} from './alert';
import {accordion} from './accordion';
import {appBar} from './appBar';
import {avatar} from './avatar';
import {bottomNavigation} from './bottomNavigation';
import {Button} from './button';
import {tabs} from './tabs';
import {fab} from './fab';
import {paper} from './paper';
import {link} from './link';
import {dataGrid} from './dataGrid';
import {dialog} from './dialog';
import {divider} from './divider';
import {drawer} from './drawer';
import {input} from './input';
import {menu} from './menu';
import {tooltip} from './tooltip';
import {stepper} from './stepper';

export const overrides = (theme: Theme) => {
  return [
    alert,
    accordion,
    appBar,
    avatar,
    bottomNavigation,
    Button,
    chip,
    dataGrid,
    dialog,
    divider,
    drawer,
    input,
    link,
    menu,
    paper,
    tabs,
    fab,
    tooltip,
    autocomplete,
    listItem,
    stepper,
  ].reduce((acc, curr) => {
    return {...acc, ...curr(theme)};
  }, {});
};
