import {opacify} from 'color2k';
import {Components, Theme} from '@mui/material/styles';
import {gridClasses} from '@mui/x-data-grid-pro';

import type {} from '@mui/x-data-grid-pro/themeAugmentation';

export const dataGrid = (
  theme: Theme
): {
  MuiDataGrid: Components['MuiDataGrid'];
} => ({
  MuiDataGrid: {
    defaultProps: {
      rowHeight: 56,
    },
    styleOverrides: {
      root: {
        // Table should have minimum height in case viewport height is too small
        minHeight: 280,

        borderColor: theme.palette.divider,
        borderRadius: theme.shape.borderRadiusLarge,
        '--DataGrid-rowBorderColor': theme.palette.divider,
      },
      virtualScroller: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      },
      main: {
        borderRadius: theme.shape.borderRadiusLarge,
      },
      cell: {
        ...theme.typography.cellText,
        borderTop: 'unset',
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        alignItems: 'center',

        [`&.${gridClasses.cell}--withRightBorder`]: {
          borderColor: theme.palette.divider,
        },
      },
      columnHeaders: {
        borderColor: theme.palette.divider,
        borderTopLeftRadius: theme.shape.borderRadiusLarge,
        borderTopRightRadius: theme.shape.borderRadiusLarge,

        '&&&& > [role=row]': {
          // use `opacity` instead of `alpha` from @mui, because it doesn't
          // make elements really transparent. This is important for pinned
          // headers
          backgroundColor: opacify(theme.palette.background.grey, 0.6),
        },
      },
      columnHeader: {
        [`&.${gridClasses.columnHeader}--withRightBorder`]: {
          borderColor: theme.palette.divider,
        },
        [`&.${gridClasses.columnHeader}--pinnedLeft`]: {
          backgroundColor: opacify(theme.palette.background.grey, 0.6),
        },
      },
      columnHeaderTitle: {
        ...theme.typography.cellHeader,
        color: theme.palette.text.secondary,
        textTransform: 'capitalize',

        '& > button': {
          color: theme.palette.text.secondary,
        },
      },
      iconButtonContainer: {
        marginLeft: theme.spacing(2),
      },
      pinnedColumns: {
        boxShadow: 'none',

        [`&.${gridClasses.pinnedColumns}--left`]: {
          borderRight: `1px solid ${theme.palette.divider}`,
        },

        [`&.${gridClasses.pinnedColumns}--right`]: {
          borderLeft: `1px solid ${theme.palette.divider}`,
        },
      },
      'pinnedRows--top': {
        boxShadow: 'none',

        '& > div:last-of-type': {
          [`& div.${gridClasses.cell}`]: {
            ...theme.typography.cellHeader,
            borderBottomWidth: 2,
          },
        },
      },
    },
  },
});
