import {toast} from 'sonner';

import {LocalStorageKey} from 'core/types';
import {Modifier} from 'modules/modifier/types';

import {IS_DEV_ENV} from 'config';
import {getModifiers} from 'modules/modifier/utils';

const withDevServiceWorker = IS_DEV_ENV && !!localStorage.getItem(LocalStorageKey.WithDevServiceWorker);

const devChannel = IS_DEV_ENV && new BroadcastChannel('dev-service-worker');

if (devChannel) {
  devChannel.addEventListener('message', event => {
    const {type, payload} = event.data;

    if (type === 'INFO') {
      toast.info(payload);
    }

    if (type === 'REQUEST') {
      toast.info(
        <div>
          Request <strong>{payload}</strong> was modified
        </div>
      );
    }
  });
}

export const initServiceWorker = () => {
  if (!('serviceWorker' in navigator)) {
    return;
  }

  if (withDevServiceWorker) {
    return registerDevServiceWorker();
  }

  return registerServiceWorker();
};

export const updateModifiers = (payload: Modifier[] = getModifiers()) => {
  if (devChannel) {
    devChannel.postMessage({
      payload,
      type: 'UPDATE_MODIFIERS',
    });
  }
};

export const unregisterServiceWorker = async () => {
  const registration = await navigator.serviceWorker.getRegistration('/');
  return registration?.unregister();
};

export const registerDevServiceWorker = async () => {
  await navigator.serviceWorker.register('/devServiceWorker.js', {
    scope: '/',
  });
  await navigator.serviceWorker.ready;
  updateModifiers();
};

export const registerServiceWorker = async () => {
  await navigator.serviceWorker.register('/serviceWorker.js', {
    scope: '/',
  });
};

export const isDevServiceWorker = async () => {
  const registration = await navigator.serviceWorker.getRegistration('/');
  return !!registration?.active?.scriptURL.endsWith('/devServiceWorker.js');
};
