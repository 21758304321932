import {PaletteMode} from '@mui/material';
import {ThemeOptions} from '@mui/material/styles';

import palette from './palette';
import spacing from './spacing';
import typography from './typography';
import {shape} from './shape';
import breakpoints from './breakpoints';
import {transitions} from './transitions';
import {containerBreakpoints} from './containerBreakpoints';
import {zIndex} from './zIndex';

export type Settings = {
  mode: PaletteMode;
};

const themeOptions = (settings: Settings): ThemeOptions => {
  const {mode} = settings;

  return {
    containerBreakpoints,
    palette: palette(mode),
    breakpoints,
    shape,
    ...spacing,
    ...typography,
    transitions,
    zIndex,
  };
};

export default themeOptions;
