import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/apolloWrapper';
const defaultOptions = {} as const;
export type MyInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyInfoQuery = { __typename?: 'Query', userInfo: { __typename?: 'UserInfo', id: string, tenantId: string, tenantName: string, roleType: Types.EnnablRoleType, ennablEmployeeId?: string | null, timeZone?: string | null, permissions: Array<{ __typename?: 'AppPermission', id: Types.AppPermissionId, name: string, description: string, isAdmin: boolean }>, profile?: { __typename?: 'UserProfile', email?: string | null, familyName?: string | null, givenName?: string | null, name?: string | null } | null, roles: Array<{ __typename?: 'EnnablRole', id: string, name: string }> } };


export const MyInfoDocument = gql`
    query MyInfo {
  userInfo {
    id
    tenantId
    tenantName
    roleType
    ennablEmployeeId
    permissions {
      id
      name
      description
      isAdmin
    }
    profile {
      email
      familyName
      givenName
      name
    }
    roles {
      id
      name
    }
    timeZone
  }
}
    `;

/**
 * __useMyInfoQuery__
 *
 * To run a query within a React component, call `useMyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyInfoQuery, MyInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MyInfoQuery, MyInfoQueryVariables>(MyInfoDocument, options);
      }
export function useMyInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyInfoQuery, MyInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MyInfoQuery, MyInfoQueryVariables>(MyInfoDocument, options);
        }
export function useMyInfoSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<MyInfoQuery, MyInfoQueryVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<MyInfoQuery, MyInfoQueryVariables>(MyInfoDocument, options);
        }
export type MyInfoQueryHookResult = ReturnType<typeof useMyInfoQuery>;
export type MyInfoLazyQueryHookResult = ReturnType<typeof useMyInfoLazyQuery>;
export type MyInfoSuspenseQueryHookResult = ReturnType<typeof useMyInfoSuspenseQuery>;
export type MyInfoQueryResult = Apollo.QueryResult<MyInfoQuery, MyInfoQueryVariables>;