import {ComponentType, forwardRef, Fragment, ReactNode, useEffect, useState} from 'react';
/* eslint-disable-next-line no-restricted-imports  */
import {createPortal} from 'react-dom';

const AMOUNT_OF_ATTEMPTS = 5;

// mimic to createPortal? (rename, swap props)
export function createPortalComponent<T extends {children?: ReactNode}>(
  nodeId: string | null,
  Wrapper: ComponentType<T> = Fragment as ComponentType<T>
) {
  return forwardRef<unknown, T>(function PortalComponent(props, ref) {
    const [counter, setCounter] = useState(AMOUNT_OF_ATTEMPTS);
    const node = nodeId ? document.getElementById(nodeId) : document.body;

    useEffect(() => {
      if (!node && counter > 0) {
        setTimeout(() => {
          setCounter(counter - 1);
        }, AMOUNT_OF_ATTEMPTS * 100 - counter * 100);
      }
    }, [counter, node]);

    if (!props.children || !node) {
      return null;
    }

    return createPortal(<Wrapper {...(props as T)} ref={ref} />, node);
  });
}
