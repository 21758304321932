import {Components, Theme} from '@mui/material/styles';
import {alertClasses} from '@mui/material';

export const alert = (theme: Theme): Pick<Components, 'MuiAlert' | 'MuiAlertTitle'> => ({
  MuiAlert: {
    styleOverrides: {
      root: {
        borderRadius: theme.shape.borderRadius,
        // Idea is that IconButton (onClose or action props) should have inner padding, so we decrease padding for the root
        padding: theme.spacing(1, 2),
        minHeight: 36,
        alignItems: 'center',

        [`&.${alertClasses.colorWarning}`]: {
          backgroundColor: theme.palette.dataTypes.carriers['70'],
          color: theme.palette.text.primary,
        },

        [`& .${alertClasses.icon}`]: {
          padding: 0,
          marginRight: 8,
          color: 'inherit',
        },

        [`& .${alertClasses.message}`]: {
          ...theme.typography.body3,
          padding: 0,
        },

        [`& .${alertClasses.action}`]: {
          padding: 0,
          margin: 0,
          marginLeft: 'auto',
        },

        variants: [
          {
            props: props => !!props.onClose,
            style: {
              [`& .${alertClasses.action}`]: {
                svg: {
                  height: 20,
                  width: 20,
                },
              },
            },
          },
        ],
      },
    },
    defaultProps: {},
  },
  MuiAlertTitle: {},
});
