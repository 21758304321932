import {AppPermissionId} from 'modules/decisionsNext/api/types';

import {COMMAND_STATUS, ENNABL_DECISIONS, ENNABL_GROWTH} from 'core/constants/paths';
import {useAppPermissions, useIsProducer} from 'core/hooks/authHooks';

export const useDefaultRoute = () => {
  const {hasPermission} = useAppPermissions();
  const isGrowthProducer = useIsProducer();

  if (hasPermission(AppPermissionId.OnlyReportStudio) || hasPermission(AppPermissionId.OnlyLossRuns)) {
    return ENNABL_DECISIONS;
  }

  if (isGrowthProducer) {
    return ENNABL_GROWTH;
  }

  if (hasPermission(AppPermissionId.ViewDecisions)) {
    return ENNABL_DECISIONS;
  }

  if (hasPermission(AppPermissionId.EditCommand)) {
    return COMMAND_STATUS;
  }

  return '/';
};
