export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: { input: number | string; output: number | string; }
};

export type Address = {
  __typename?: 'Address';
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  streetLine1?: Maybe<Scalars['String']['output']>;
  streetLine2?: Maybe<Scalars['String']['output']>;
};

export type AddressUpdateInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  streetLine1?: InputMaybe<Scalars['String']['input']>;
  streetLine2?: InputMaybe<Scalars['String']['input']>;
};

export type AdminProduct = {
  __typename?: 'AdminProduct';
  buyer?: Maybe<ProductBuyer>;
  group: Scalars['String']['output'];
  hideOnDecisions: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  insuranceSegment: Scalars['String']['output'];
  isFee: Scalars['Boolean']['output'];
  isOpportunity: Scalars['Boolean']['output'];
  isPackage: Scalars['Boolean']['output'];
  isRenewable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  superGroup: Scalars['String']['output'];
};

export enum AffectedEntity {
  Client = 'CLIENT',
  Department = 'DEPARTMENT',
  InsuranceCompany = 'INSURANCE_COMPANY',
  Product = 'PRODUCT'
}

export type Agency = {
  __typename?: 'Agency';
  departmentIds?: Maybe<Scalars['String']['output']>;
  externalSystemCount: Scalars['Int']['output'];
  externalSystems: Array<ExternalSystem>;
  id: Scalars['ID']['output'];
  issues: Array<Issue>;
  lastEtlDate?: Maybe<Scalars['Timestamp']['output']>;
  lastUpdated: Scalars['Timestamp']['output'];
  name: Scalars['String']['output'];
  nextEtlDate?: Maybe<Scalars['Timestamp']['output']>;
};

export type Alert = {
  __typename?: 'Alert';
  affectedActivePoliciesCount: Scalars['Int']['output'];
  affectedEntityId: Scalars['String']['output'];
  affectedEntityName: Scalars['String']['output'];
  affectedEntityType: AffectedEntity;
  affectedPremiumAmount: Scalars['Float']['output'];
  affectedRevenueAmount: Scalars['Float']['output'];
  date: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  isAffectingActivePolicies: Scalars['Boolean']['output'];
  type: AlertType;
};

export type AlertGroup = {
  __typename?: 'AlertGroup';
  affectedPremiumAmount: Scalars['Float']['output'];
  affectedRevenueAmount: Scalars['Float']['output'];
  countByAge: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  type: AlertType;
};


export type AlertGroupCountByAgeArgs = {
  fromDays?: InputMaybe<Scalars['Float']['input']>;
  toDays?: InputMaybe<Scalars['Float']['input']>;
};

export type AlertSearchResult = {
  __typename?: 'AlertSearchResult';
  alerts: Array<Alert>;
  totalHits: Scalars['Int']['output'];
};

export enum AlertSortBy {
  AffectedRevenue = 'AFFECTED_REVENUE',
  Date = 'DATE'
}

export enum AlertType {
  DepartmentMappingIssue = 'DEPARTMENT_MAPPING_ISSUE',
  IndustryMappingIssue = 'INDUSTRY_MAPPING_ISSUE',
  InsuranceCompanyMappingIssue = 'INSURANCE_COMPANY_MAPPING_ISSUE',
  ProductMappingIssue = 'PRODUCT_MAPPING_ISSUE'
}

export type Amount = {
  __typename?: 'Amount';
  commission: Scalars['Float']['output'];
  fee: Scalars['Float']['output'];
  premium: Scalars['Float']['output'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  id: Scalars['ID']['output'];
  publicKey: Scalars['String']['output'];
  s3Url: Scalars['String']['output'];
  secretKey: Scalars['String']['output'];
};

export type AppPermission = {
  __typename?: 'AppPermission';
  description: Scalars['String']['output'];
  id: PermissionId;
  isAdmin: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type Band = {
  __typename?: 'Band';
  benefitsName?: Maybe<Scalars['String']['output']>;
  commercialName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  maxValue?: Maybe<Scalars['Float']['output']>;
  minValue: Scalars['Float']['output'];
  plName?: Maybe<Scalars['String']['output']>;
};

export type BandLabelEdit = {
  benefitsName?: InputMaybe<Scalars['String']['input']>;
  commercialName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  plName?: InputMaybe<Scalars['String']['input']>;
};

export type BatchUserDeleteInput = {
  userIds: Array<Scalars['ID']['input']>;
};

export type BatchUserUpdateInput = {
  status?: InputMaybe<UserStatus>;
  userIds: Array<Scalars['ID']['input']>;
};

export type Broker = {
  __typename?: 'Broker';
  name: Scalars['String']['output'];
};

export enum BrokerMarketStatus {
  NonStrategic = 'NON_STRATEGIC',
  Other = 'OTHER',
  Strategic = 'STRATEGIC'
}

export type Client = {
  __typename?: 'Client';
  activeBilledAmount?: Maybe<Amount>;
  activeBilledRevenue?: Maybe<Scalars['Float']['output']>;
  activeNumberOfPolicies?: Maybe<Scalars['Int']['output']>;
  activeWrittenAmount?: Maybe<Amount>;
  clientNumber?: Maybe<Scalars['String']['output']>;
  domesticUltimateDuns?: Maybe<Scalars['String']['output']>;
  domesticUltimateName?: Maybe<Scalars['String']['output']>;
  duns?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employees?: Maybe<Scalars['Int']['output']>;
  externalSystem: ExternalSystem;
  id: Scalars['ID']['output'];
  marketCap?: Maybe<Scalars['Float']['output']>;
  naics?: Maybe<Scalars['String']['output']>;
  naicsDescription?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  primaryAddress?: Maybe<Address>;
  sales?: Maybe<Scalars['Float']['output']>;
  sic?: Maybe<Scalars['String']['output']>;
  sourceId: Scalars['String']['output'];
  sourceName: Scalars['String']['output'];
  suggestions?: Maybe<Array<ClientSuggestions>>;
  telephoneNumber?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ClientType>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ClientSearchResult = {
  __typename?: 'ClientSearchResult';
  clients: Array<Client>;
  totalHits: Scalars['Int']['output'];
  totalHitsIsLowerBound?: Maybe<Scalars['Boolean']['output']>;
};

export enum ClientSortBy {
  AgencyNameAndExternalSystemType = 'AGENCY_NAME_AND_EXTERNAL_SYSTEM_TYPE',
  ExternalId = 'EXTERNAL_ID',
  MissingFieldCount = 'MISSING_FIELD_COUNT',
  Name = 'NAME',
  Revenue = 'REVENUE'
}

export type ClientSuggestion = {
  __typename?: 'ClientSuggestion';
  data: ClientSuggestionData;
  id: Scalars['ID']['output'];
  score?: Maybe<Scalars['Float']['output']>;
};

export type ClientSuggestionData = {
  __typename?: 'ClientSuggestionData';
  name: Scalars['String']['output'];
  primaryAddress?: Maybe<Address>;
  telephoneNumber?: Maybe<Scalars['String']['output']>;
  websiteAddress?: Maybe<Scalars['String']['output']>;
};

export type ClientSuggestions = {
  __typename?: 'ClientSuggestions';
  dataSourceId: Scalars['String']['output'];
  /** @deprecated replaced by dataSourceId */
  integrationType: Scalars['String']['output'];
  suggestions: Array<ClientSuggestion>;
};

export enum ClientType {
  Company = 'COMPANY',
  Person = 'PERSON'
}

export type ClientUpdateInput = {
  clientNumber?: InputMaybe<Scalars['String']['input']>;
  duns?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employees?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  marketCap?: InputMaybe<Scalars['Int']['input']>;
  naics?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  primaryAddress?: InputMaybe<AddressUpdateInput>;
  sales?: InputMaybe<Scalars['Int']['input']>;
  sic?: InputMaybe<Scalars['String']['input']>;
  telephoneNumber?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ClientType>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CsvReport = {
  __typename?: 'CsvReport';
  url: Scalars['String']['output'];
};

export type DecisionsPermission = {
  __typename?: 'DecisionsPermission';
  clientIds?: Maybe<Array<Scalars['String']['output']>>;
  departmentIds?: Maybe<Array<Scalars['String']['output']>>;
  industryIds?: Maybe<Array<Scalars['String']['output']>>;
  productIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type DecisionsPermissionInput = {
  clientIds?: InputMaybe<Array<Scalars['String']['input']>>;
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  industryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  productIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DecisionsPermissions = {
  __typename?: 'DecisionsPermissions';
  allowed: Array<DecisionsPermission>;
};

export type DecisionsPermissionsInput = {
  allowed: Array<DecisionsPermissionInput>;
};

export type Department = {
  __typename?: 'Department';
  amsCode: Scalars['String']['output'];
  externalSystem: ExternalSystem;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parents: Array<DepartmentParent>;
};

export enum DepartmentEditErrorCode {
  DepartmentDoesNotExist = 'DEPARTMENT_DOES_NOT_EXIST'
}

export type DepartmentLevel = {
  __typename?: 'DepartmentLevel';
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type DepartmentParent = {
  __typename?: 'DepartmentParent';
  id: Scalars['ID']['output'];
  level: DepartmentLevel;
  name: Scalars['String']['output'];
};

export type DepartmentParentUpdateInput = {
  levelIndex: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type DepartmentUpdateInput = {
  ids: Array<Scalars['String']['input']>;
  parents: Array<DepartmentParentUpdateInput>;
};

export type DepartmentUpdateResult = {
  __typename?: 'DepartmentUpdateResult';
  errorCode?: Maybe<DepartmentEditErrorCode>;
  updatedDepartments: Array<Department>;
};

export type EnnablRole = {
  __typename?: 'EnnablRole';
  appPermissions: Array<AppPermission>;
  decisionsPermissions?: Maybe<DecisionsPermissions>;
  id: Scalars['ID']['output'];
  /** This is custom access role created for specific user */
  isPrivate: Scalars['Boolean']['output'];
  /** Predefined roles are not allowed to be removed or updated (except name) */
  isProtected: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export enum EnnablRoleType {
  AccountExecutive = 'ACCOUNT_EXECUTIVE',
  AccountManager = 'ACCOUNT_MANAGER',
  Admin = 'ADMIN',
  Custom = 'CUSTOM',
  Producer = 'PRODUCER'
}

export type EnrichedEntity = {
  __typename?: 'EnrichedEntity';
  alertType: AlertType;
  date: Scalars['Timestamp']['output'];
  eligibleForEnrichment: Scalars['Int']['output'];
  enriched: Scalars['Int']['output'];
  enrichedPct: Scalars['Float']['output'];
  entityType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  issuesCount: Scalars['Int']['output'];
};

export type EnrichedInsuranceCompany = {
  __typename?: 'EnrichedInsuranceCompany';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  primaryRelationship: InsuranceCompanyRelationshipType;
};

export type EnrichedProduct = {
  __typename?: 'EnrichedProduct';
  adminProduct?: Maybe<AdminProduct>;
  buyer?: Maybe<ProductBuyer>;
  group?: Maybe<Scalars['String']['output']>;
  hideOnDecisions?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  insuranceSegment?: Maybe<Scalars['String']['output']>;
  isFee?: Maybe<Scalars['Boolean']['output']>;
  isOpportunity?: Maybe<Scalars['Boolean']['output']>;
  isPackage?: Maybe<Scalars['Boolean']['output']>;
  isRenewable?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  superGroup?: Maybe<Scalars['String']['output']>;
};

export type EtlStatistic = {
  __typename?: 'EtlStatistic';
  date: Scalars['Timestamp']['output'];
  externalSystem: ExternalSystem;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  valueInt?: Maybe<Scalars['Int']['output']>;
  valueMoney?: Maybe<Scalars['Int']['output']>;
};

export type ExistingMappingInput = {
  id: Scalars['String']['input'];
  mapTo: MapToExistingInput;
};

export type ExistingMappingInputBatch = {
  ids: Array<Scalars['String']['input']>;
  mapTo: MapToExistingInput;
};

export type ExternalSystem = {
  __typename?: 'ExternalSystem';
  agency: Agency;
  healthStatus: ExternalSystemHealthStatus;
  /** system id with tenantId prefix */
  id: Scalars['ID']['output'];
  issues: Array<Issue>;
  lastEtlDate: Scalars['Timestamp']['output'];
  name: Scalars['String']['output'];
  nextScheduledEtlDate: Scalars['Timestamp']['output'];
  numberOfRows: Scalars['Int']['output'];
  status: ExternalSystemStatus;
  /** system id without prefix */
  systemId: Scalars['String']['output'];
};

export type ExternalSystemFilterInput = {
  externalSystemId?: InputMaybe<Scalars['String']['input']>;
  externalSystemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<ExternalSystemStatus>;
};

export enum ExternalSystemHealthStatus {
  Offline = 'OFFLINE',
  Online = 'ONLINE'
}

export enum ExternalSystemStatus {
  Onboarded = 'ONBOARDED',
  Onboarding = 'ONBOARDING'
}

export enum FilterByMissingData {
  AllClients = 'ALL_CLIENTS',
  OnlyWithFullData = 'ONLY_WITH_FULL_DATA',
  OnlyWithMissingData = 'ONLY_WITH_MISSING_DATA'
}

export type Industry = {
  __typename?: 'Industry';
  id: Scalars['ID']['output'];
  naics: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<IndustryGroup>;
  /** @deprecated It's always empty */
  sic: Array<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export enum IndustryEditErrorCode {
  NaicsDoesNotExist = 'NAICS_DOES_NOT_EXIST'
}

export type IndustryEditInput = {
  group: Scalars['String']['input'];
  naics: Array<Scalars['String']['input']>;
  subGroup?: InputMaybe<Scalars['String']['input']>;
};

export type IndustryGroup = {
  __typename?: 'IndustryGroup';
  children: Array<IndustryGroupChild>;
  id: Scalars['ID']['output'];
  industryCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<IndustryGroup>;
  type: Scalars['String']['output'];
};

export type IndustryGroupChild = Industry | IndustryGroup;

export type IndustryUpdateResult = {
  __typename?: 'IndustryUpdateResult';
  errorCode?: Maybe<IndustryEditErrorCode>;
  industryGroupTree?: Maybe<IndustryGroup>;
  updatedIndustries: Array<Industry>;
};

export type InsuranceCompany = {
  __typename?: 'InsuranceCompany';
  amsName?: Maybe<Scalars['String']['output']>;
  enrichedId?: Maybe<Scalars['String']['output']>;
  externalSystem?: Maybe<ExternalSystem>;
  /** if the mapping is merged by name, this field has the stats of one of them, since stats may overlap in this case */
  fullNumberOfPolicies?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  /** @deprecated use mappingIds */
  mappingId?: Maybe<Scalars['String']['output']>;
  /** ids of all mapping with the same ams name and external system */
  mappingIds?: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  parent?: Maybe<InsuranceCompany>;
  payeeType: PayeeType;
  ultimateParent?: Maybe<InsuranceCompany>;
};

export type InsuranceCompanyMapping = {
  __typename?: 'InsuranceCompanyMapping';
  amsName: Scalars['String']['output'];
  company?: Maybe<EnrichedInsuranceCompany>;
  externalSystemId: Scalars['String']['output'];
  externalSystemName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  ids: Array<Scalars['String']['output']>;
  mapped: Scalars['Boolean']['output'];
  parent?: Maybe<EnrichedInsuranceCompany>;
  policyCount: Scalars['Float']['output'];
  ultimateParent?: Maybe<EnrichedInsuranceCompany>;
};

export type InsuranceCompanyMappingParams = {
  externalSystemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  mapped?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<InsuranceCompanyMappingSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type InsuranceCompanyMappingResponse = {
  __typename?: 'InsuranceCompanyMappingResponse';
  items: Array<InsuranceCompanyMapping>;
  totalHits: Scalars['Int']['output'];
};

export enum InsuranceCompanyMappingSortBy {
  AmsName = 'AMS_NAME',
  CompanyName = 'COMPANY_NAME',
  EnrichedInsuranceCompanyPrimaryRelationship = 'ENRICHED_INSURANCE_COMPANY_PRIMARY_RELATIONSHIP',
  ExternalSystemName = 'EXTERNAL_SYSTEM_NAME',
  Mapped = 'MAPPED',
  ParentCompanyName = 'PARENT_COMPANY_NAME',
  UltimateParentCompanyName = 'ULTIMATE_PARENT_COMPANY_NAME'
}

export enum InsuranceCompanyRelationshipType {
  Carrier = 'CARRIER',
  Intermediary = 'INTERMEDIARY'
}

export enum InsuranceCompanySortBy {
  AmsName = 'AMS_NAME',
  Name = 'NAME'
}

export type InsuranceCompanySuggestion = {
  __typename?: 'InsuranceCompanySuggestion';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<InsuranceCompanySuggestion>;
  payeeType: PayeeType;
  state?: Maybe<Scalars['String']['output']>;
  ultimateParent?: Maybe<InsuranceCompanySuggestion>;
};

export type InsuranceCompanyTree = {
  __typename?: 'InsuranceCompanyTree';
  children?: Maybe<Array<InsuranceCompanyTree>>;
  companyStatus?: Maybe<BrokerMarketStatus>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  primaryRelationship: InsuranceCompanyRelationshipType;
};

export type InsuranceCompanyTreeParams = {
  externalSystemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  primaryRelationship?: InputMaybe<InsuranceCompanyRelationshipType>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type InsuranceCompanyTreeResponse = {
  __typename?: 'InsuranceCompanyTreeResponse';
  items: Array<InsuranceCompanyTree>;
  totalHits: Scalars['Int']['output'];
};

export type Integration = {
  __typename?: 'Integration';
  id: Scalars['ID']['output'];
  issues: Array<Issue>;
  lastCall: Scalars['Timestamp']['output'];
  name: Scalars['String']['output'];
  numberOfCalls: Scalars['Int']['output'];
};

export type Issue = {
  __typename?: 'Issue';
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
};

export type LogRecord = {
  __typename?: 'LogRecord';
  date: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
  userEmail: Scalars['String']['output'];
};

export enum LogRecordSortBy {
  Date = 'DATE',
  User = 'USER'
}

export type MapToExistingInput = {
  id: Scalars['String']['input'];
};

export enum MappingStatus {
  Mapped = 'MAPPED',
  PendingAuto = 'PENDING_AUTO',
  PendingUser = 'PENDING_USER',
  Skip = 'SKIP'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** @deprecated Outdated functionality */
  changeLabels: Scalars['Boolean']['output'];
  /** @deprecated Outdated functionality */
  changeLabelsBulk: Scalars['Boolean']['output'];
  createEnnablRole: EnnablRole;
  deleteUserBatch: UserBatchDeletePayload;
  exportCompanyMappings: CsvReport;
  generateApiKey: ApiKey;
  inviteUser: Scalars['Boolean']['output'];
  mapInsuranceCompaniesBatch: Scalars['Boolean']['output'];
  mapInsuranceCompanyToExisting: Scalars['Boolean']['output'];
  productsMappingReport: ProductMappingCsvReport;
  removeEnnablRole: Array<User>;
  resendEmail: Scalars['Boolean']['output'];
  selectSuggestion: Client;
  updateAndFetchIndustry: IndustryUpdateResult;
  updateClient: Client;
  updateDepartments: DepartmentUpdateResult;
  updateEnnablRole: EnnablRole;
  updateIndustry?: Maybe<IndustryEditErrorCode>;
  updateOwnUser: User;
  updateProduct: ProductUpdateOutput;
  updateProducts: ProductUpdateResult;
  updateTransactionCodes: TransactionCodeUpdateResult;
  updateUser: User;
  updateUserBatch: Array<User>;
};


export type MutationChangeLabelsArgs = {
  input: BandLabelEdit;
};


export type MutationChangeLabelsBulkArgs = {
  input: Array<BandLabelEdit>;
};


export type MutationCreateEnnablRoleArgs = {
  input: RoleCreateInput;
};


export type MutationDeleteUserBatchArgs = {
  input: BatchUserDeleteInput;
};


export type MutationExportCompanyMappingsArgs = {
  params?: InputMaybe<InsuranceCompanyMappingParams>;
};


export type MutationInviteUserArgs = {
  input?: InputMaybe<UserInviteInput>;
};


export type MutationMapInsuranceCompaniesBatchArgs = {
  input: ExistingMappingInputBatch;
};


export type MutationMapInsuranceCompanyToExistingArgs = {
  input: ExistingMappingInput;
};


export type MutationProductsMappingReportArgs = {
  filterToExternalSystemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  mapped?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationRemoveEnnablRoleArgs = {
  id: Scalars['String']['input'];
};


export type MutationResendEmailArgs = {
  userId: Scalars['String']['input'];
};


export type MutationSelectSuggestionArgs = {
  input: SelectClientSuggestionInput;
};


export type MutationUpdateAndFetchIndustryArgs = {
  input?: InputMaybe<IndustryEditInput>;
};


export type MutationUpdateClientArgs = {
  input: ClientUpdateInput;
};


export type MutationUpdateDepartmentsArgs = {
  input: DepartmentUpdateInput;
};


export type MutationUpdateEnnablRoleArgs = {
  id: Scalars['String']['input'];
  input: RoleUpdateInput;
};


export type MutationUpdateIndustryArgs = {
  input?: InputMaybe<IndustryEditInput>;
};


export type MutationUpdateOwnUserArgs = {
  input: OwnUserUpdateInput;
};


export type MutationUpdateProductArgs = {
  input?: InputMaybe<OneProductUpdateInput>;
};


export type MutationUpdateProductsArgs = {
  input: ProductUpdateInput;
};


export type MutationUpdateTransactionCodesArgs = {
  input: TransactionCodeUpdateInput;
};


export type MutationUpdateUserArgs = {
  input: UserUpdateInput;
};


export type MutationUpdateUserBatchArgs = {
  input: BatchUserUpdateInput;
};

export type OneProductUpdateInput = {
  adminProductId?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  hideOnDecisions?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  insuranceSegment?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  superGroup?: InputMaybe<Scalars['String']['input']>;
};

export type OwnUserUpdateInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export enum PayeeType {
  Carrier = 'CARRIER',
  Intermediary = 'INTERMEDIARY',
  Other = 'OTHER',
  PendingReview = 'PENDING_REVIEW',
  Unmapped = 'UNMAPPED'
}

export enum PermissionId {
  CrosscheckDevRealData = 'CROSSCHECK_DEV_REAL_DATA',
  EditCommand = 'EDIT_COMMAND',
  ExportDecisions = 'EXPORT_DECISIONS',
  OnlyLossRuns = 'ONLY_LOSS_RUNS',
  OnlyReportStudio = 'ONLY_REPORT_STUDIO',
  ViewDecisions = 'VIEW_DECISIONS'
}

export type Product = {
  __typename?: 'Product';
  amsCode?: Maybe<Scalars['String']['output']>;
  amsInsuranceSegment?: Maybe<Scalars['String']['output']>;
  amsName: Scalars['String']['output'];
  enrichedProduct: EnrichedProduct;
  externalSystem: ExternalSystem;
  fullNumberOfPolicies?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use enrichedProduct */
  group: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Use enrichedProduct */
  insuranceSegment: Scalars['String']['output'];
  mapped: Scalars['Boolean']['output'];
  /** @deprecated Use enrichedProduct */
  name: Scalars['String']['output'];
  /** @deprecated Use enrichedProduct */
  superGroup: Scalars['String']['output'];
};

export enum ProductBuyer {
  Company = 'COMPANY',
  Person = 'PERSON'
}

export enum ProductEditErrorCode {
  ProductDoesNotExist = 'PRODUCT_DOES_NOT_EXIST'
}

export type ProductMappingCsvReport = {
  __typename?: 'ProductMappingCsvReport';
  url: Scalars['String']['output'];
};

export type ProductUpdateInput = {
  group?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['String']['input']>;
  insuranceSegment?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  superGroup?: InputMaybe<Scalars['String']['input']>;
};

export type ProductUpdateOutput = {
  __typename?: 'ProductUpdateOutput';
  success: Scalars['Boolean']['output'];
};

export type ProductUpdateResult = {
  __typename?: 'ProductUpdateResult';
  /** @deprecated Is not supported */
  errorCode?: Maybe<ProductEditErrorCode>;
  /** @deprecated Is not supported */
  updatedProducts: Array<Product>;
};

export type Query = {
  __typename?: 'Query';
  adminProducts: Array<AdminProduct>;
  alertGroups: Array<AlertGroup>;
  alerts: Array<Alert>;
  alertsV2: AlertSearchResult;
  appPermissions: Array<AppPermission>;
  broker: Broker;
  brokerAgencies: Array<Agency>;
  brokerAgencyCount: Scalars['Int']['output'];
  brokerIssuesPct: Scalars['Float']['output'];
  brokerLastUpdated: Scalars['Timestamp']['output'];
  clients: ClientSearchResult;
  departmentLevels: Array<DepartmentLevel>;
  departments: Array<Department>;
  ennablRoles: Array<EnnablRole>;
  enrichedEntitiesCount: Scalars['Int']['output'];
  enrichmentIssuesPct: Scalars['Float']['output'];
  enrichments: Array<EnrichedEntity>;
  esInsuranceCompanies: Array<InsuranceCompany>;
  etlStats: Array<EtlStatistic>;
  externalSystemCount: Scalars['Int']['output'];
  externalSystemIssuesPct: Scalars['Float']['output'];
  externalSystemLastEtlDate: Scalars['Timestamp']['output'];
  externalSystems: Array<ExternalSystem>;
  getCompanyMappings: InsuranceCompanyMappingResponse;
  getTreeInsuranceCompanies: InsuranceCompanyTreeResponse;
  industries: Array<Industry>;
  industryRoot: IndustryGroup;
  insuranceCompaniesSuggestions: Array<InsuranceCompanySuggestion>;
  integrations: Array<Integration>;
  integrationsCount: Scalars['Int']['output'];
  integrationsIssuesPct: Scalars['Float']['output'];
  integrationsLastCallDate: Scalars['Timestamp']['output'];
  logRecords: Array<LogRecord>;
  /** @deprecated Outdated functionality */
  numOfLivesBands: Array<Band>;
  /** @deprecated Outdated functionality */
  premiumBands: Array<Band>;
  products: Array<Product>;
  /** @deprecated Outdated functionality */
  revenueBands: Array<Band>;
  rowsMoved: Scalars['Int']['output'];
  rowsMovedIssuesPct: Scalars['Float']['output'];
  rowsMovedLastDate: Scalars['Timestamp']['output'];
  transactionCodes: Array<TransactionCodeMapping>;
  user?: Maybe<User>;
  userCount: Scalars['Int']['output'];
  /** @deprecated Use Decisions userInfo instead */
  userInfo: UserInfo;
  userIssuesPct: Scalars['Float']['output'];
  userLastInvited: Scalars['Timestamp']['output'];
  users: Array<User>;
  validationValues: Array<ValidationValue>;
};


export type QueryAdminProductsArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAlertsArgs = {
  alertType?: InputMaybe<Array<AlertType>>;
  fromDays?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<AlertSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  toDays?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAlertsV2Args = {
  alertType?: InputMaybe<Array<AlertType>>;
  fromDays?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<AlertSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  toDays?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBrokerAgenciesArgs = {
  filter?: InputMaybe<ExternalSystemFilterInput>;
};


export type QueryBrokerAgencyCountArgs = {
  filter?: InputMaybe<ExternalSystemFilterInput>;
};


export type QueryClientsArgs = {
  fieldsToConsiderWhenCheckingMissingData?: InputMaybe<Array<Scalars['String']['input']>>;
  filterByMissingData?: InputMaybe<FilterByMissingData>;
  filterToClientType?: InputMaybe<ClientType>;
  filterToExternalSystemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  filterToIncludeOnlyClientsWithMissingData?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<ClientSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryEnrichedEntitiesCountArgs = {
  filter?: InputMaybe<ExternalSystemFilterInput>;
};


export type QueryEnrichmentIssuesPctArgs = {
  filter?: InputMaybe<ExternalSystemFilterInput>;
};


export type QueryEnrichmentsArgs = {
  filter?: InputMaybe<ExternalSystemFilterInput>;
};


export type QueryEsInsuranceCompaniesArgs = {
  externalSystemId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  payeeType?: InputMaybe<PayeeType>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<InsuranceCompanySortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  ultimateParentId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryEtlStatsArgs = {
  entity?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ExternalSystemFilterInput>;
  from: Scalars['Timestamp']['input'];
  to: Scalars['Timestamp']['input'];
};


export type QueryExternalSystemCountArgs = {
  filter?: InputMaybe<ExternalSystemFilterInput>;
};


export type QueryExternalSystemIssuesPctArgs = {
  filter?: InputMaybe<ExternalSystemFilterInput>;
};


export type QueryExternalSystemLastEtlDateArgs = {
  filter?: InputMaybe<ExternalSystemFilterInput>;
};


export type QueryExternalSystemsArgs = {
  filter?: InputMaybe<ExternalSystemFilterInput>;
};


export type QueryGetCompanyMappingsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  params?: InputMaybe<InsuranceCompanyMappingParams>;
};


export type QueryGetTreeInsuranceCompaniesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  params?: InputMaybe<InsuranceCompanyTreeParams>;
};


export type QueryInsuranceCompaniesSuggestionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchQuery: Scalars['String']['input'];
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryLogRecordsArgs = {
  endDate?: InputMaybe<Scalars['Timestamp']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<LogRecordSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<Scalars['Timestamp']['input']>;
};


export type QueryProductsArgs = {
  filterToExternalSystemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  mapped?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryRowsMovedArgs = {
  entity?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ExternalSystemFilterInput>;
};


export type QueryRowsMovedIssuesPctArgs = {
  entity?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ExternalSystemFilterInput>;
};


export type QueryRowsMovedLastDateArgs = {
  entity?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ExternalSystemFilterInput>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryValidationValuesArgs = {
  differentiator: ValidationDifferentiator;
  externalSystemFilter?: InputMaybe<ExternalSystemFilterInput>;
  externalSystemId?: InputMaybe<Scalars['String']['input']>;
};

export type RoleCreateInput = {
  appPermissionIds: Array<PermissionId>;
  decisionsPermissions?: InputMaybe<DecisionsPermissionsInput>;
  /** This is custom access role created for specific user */
  isPrivate: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type RoleUpdateInput = {
  appPermissionIds: Array<PermissionId>;
  decisionsPermissions?: InputMaybe<DecisionsPermissionsInput>;
  name: Scalars['String']['input'];
};

export type SelectClientSuggestionInput = {
  id: Scalars['ID']['input'];
  integrationType: Scalars['String']['input'];
  suggestionId?: InputMaybe<Scalars['String']['input']>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type TransactionCodeMapping = {
  __typename?: 'TransactionCodeMapping';
  code: Scalars['String']['output'];
  externalSystem: ExternalSystem;
  group?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  status: MappingStatus;
  type: TransactionType;
};

export type TransactionCodeUpdateInput = {
  ids: Array<Scalars['ID']['input']>;
  type: TransactionType;
};

export type TransactionCodeUpdateResult = {
  __typename?: 'TransactionCodeUpdateResult';
  updatedMappings: Array<TransactionCodeMapping>;
};

export enum TransactionType {
  Common = 'COMMON',
  Fee = 'FEE',
  Fee_3RdParty = 'FEE_3RD_PARTY',
  Finance = 'FINANCE',
  Skip = 'SKIP',
  Tax = 'TAX'
}

export type User = {
  __typename?: 'User';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  ennablEmployeeId?: Maybe<Scalars['String']['output']>;
  /** Roles for V2 user management page */
  ennablRoles: Array<EnnablRole>;
  firstName?: Maybe<Scalars['String']['output']>;
  hasFullDecisionsAccess: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  invitedDate: Scalars['Timestamp']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  loggedInDate?: Maybe<Scalars['Timestamp']['output']>;
  /** phone number for the reference. Not used in MFA */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  status: UserStatus;
};

export type UserBatchDeletePayload = {
  __typename?: 'UserBatchDeletePayload';
  success: Scalars['Boolean']['output'];
};

export type UserInfo = {
  __typename?: 'UserInfo';
  ennablEmployeeId?: Maybe<Scalars['String']['output']>;
  hasFullDecisionsAccess: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  permissionIds: Array<Scalars['String']['output']>;
  permissions: Array<AppPermission>;
  profile?: Maybe<UserProfile>;
  roleType: EnnablRoleType;
  roles: Array<EnnablRole>;
  tenantId: Scalars['String']['output'];
  tenantName: Scalars['String']['output'];
};

export type UserInviteInput = {
  email: Scalars['String']['input'];
  ennablEmployeeId?: InputMaybe<Scalars['String']['input']>;
  ennablRoleId?: InputMaybe<Scalars['String']['input']>;
  ennablRoleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** phone number for the reference. Not used in MFA */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** ids of the roles to be assigned to the user */
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  email?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** @deprecated No picture anymore */
  picture?: Maybe<Scalars['String']['output']>;
  /** @deprecated No updatedAt anymore */
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Removed = 'REMOVED',
  Suspended = 'SUSPENDED'
}

export type UserUpdateInput = {
  email: Scalars['String']['input'];
  ennablEmployeeId?: InputMaybe<Scalars['String']['input']>;
  ennablRoleId?: InputMaybe<Scalars['String']['input']>;
  ennablRoleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** phone number for the reference. Not used in MFA */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** ids of the roles to be assigned to the user */
  roleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<UserStatus>;
};

export enum ValidationDifferentiator {
  Agency = 'AGENCY',
  DepartmentCode = 'DEPARTMENT_CODE',
  ExternalSystem = 'EXTERNAL_SYSTEM',
  InsuranceSegment = 'INSURANCE_SEGMENT',
  InsuranceSegmentByAgency = 'INSURANCE_SEGMENT_BY_AGENCY',
  InsuranceSegmentByExternalSystem = 'INSURANCE_SEGMENT_BY_EXTERNAL_SYSTEM',
  Total = 'TOTAL'
}

export enum ValidationEntity {
  Clients = 'CLIENTS',
  Policies = 'POLICIES',
  Premium = 'PREMIUM',
  Revenue = 'REVENUE'
}

export type ValidationPeriod = {
  __typename?: 'ValidationPeriod';
  type: ValidationPeriodType;
  year?: Maybe<Scalars['Int']['output']>;
};

export enum ValidationPeriodType {
  FullYear = 'FULL_YEAR',
  InForce = 'IN_FORCE',
  Rolling_12 = 'ROLLING_12',
  Ytd = 'YTD'
}

export type ValidationValue = {
  __typename?: 'ValidationValue';
  agency?: Maybe<Agency>;
  departmentCode?: Maybe<Scalars['String']['output']>;
  entity: ValidationEntity;
  externalSystem?: Maybe<ExternalSystem>;
  id: Scalars['ID']['output'];
  insuranceSegment?: Maybe<Scalars['String']['output']>;
  period: ValidationPeriod;
  value: Scalars['Float']['output'];
};
